import { Button, FormSelect, FormFileInput } from '../../../../components';
import '../../AddMedia.scss';
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from 'react-hook-form';
import {
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  Button as ButtonRs,
} from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import Swal from 'sweetalert2';
import { ILoginFormError } from '../../../../interfaces/forms';
import { IStep2FormInput } from '../../../../interfaces/forms/AutographOrderForm/IStep2Form';
import { usePatchAutographOrderMutation } from '../../../../app/services/autographServerApi';
import { setCurrentAutographOrderStep } from '../../../../app/slices/addAutographOrderSlice';

export const Step2 = () => {
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IStep2FormInput>({});

  const {
    fields: documentFields,
    append: documentAppend,
    remove: documentRemove,
  } = useFieldArray({ control, name: 'documents' });

  const [patchAutographOrder, { isLoading, isError, isSuccess, error }] =
    usePatchAutographOrderMutation();

  const onSubmit: SubmitHandler<IStep2FormInput> = (data) => {
    console.log('DATA MOVIE STEP 8:', data);
    // dispatch(setLanguagesMovieData(data));
    // dispatch(setCurrentMovieStep(9));
    data.step = 'documents_data';

    patchAutographOrder(data).then(function (result: any) {
      if (result && result.data) {
        dispatch(setCurrentAutographOrderStep(3));
      }
    });
  };
  const documentTypes = useAppSelector(
    (state: RootState) => state.addAutographOrder.documentTypes,
  );

  console.log("THE ERRORS I'M LOOKING FOR:", errors);

  {
    isLoading &&
      Swal.fire({
        title: 'Loading',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
  }

  {
    isSuccess &&
      // Swal.fire({
      //   title: 'Logged in succesfully',
      //   text: 'Redirecting to home page',
      //   icon: 'success',
      //   timer: 1000,
      //   showConfirmButton: false,
      // });
      Swal.close();
  }

  const isLoginError = (error: any): error is ILoginFormError =>
    'status' in error;

  {
    isError &&
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        confirmButtonColor: 'green',
        footer: isLoginError(error)
          ? (error as ILoginFormError).data?.Error ||
            (error as ILoginFormError).error
          : 'An error ocurred',
      });
  }

  return (
    <>
      <div className="big-form-container">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <h2 className="media-h2">Documents</h2>
          {documentFields.map((field, index) => (
            <Row key={field.id}>
              <Col md={5}>
                <FormGroup>
                  <Label for="type">Type *</Label>
                  <Controller
                    // key={field.id}
                    name={`documents.${index}.type`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FormSelect
                        field={field}
                        options={documentTypes}
                        placeholder="Select the document's type"
                        error={errors.documents?.[index]}
                      />
                    )}
                  />

                  {errors.documents?.[index] && (
                    <div className="invalid-message">
                      {"The document's type is required"}
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label for="file">File *</Label>
                  <FormFileInput
                    control={control}
                    name={`documents.${index}.file`}
                    id="file"
                    placeholder={'Select the file'}
                    required={true}
                    error={errors.documents?.[index]}
                    accept=".pdf,.jpg,.png"
                  />
                  {errors.documents?.[index] && (
                    <div className="invalid-message">
                      {'The file is required'}
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col md={1}>
                <ButtonRs
                  type="button"
                  color="danger"
                  onClick={() => {
                    documentRemove(index);
                  }}
                >
                  X
                </ButtonRs>
              </Col>
            </Row>
          ))}

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="button"
              onClick={() => {
                documentAppend({
                  type: null,
                });
              }}
            >
              Add Document
            </Button>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="submit">Submit</Button>
          </div>
        </Form>
      </div>
    </>
  );
};
