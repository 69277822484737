import { Button, FormSelect } from '../../../../components';
import '../../AddMedia.scss';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  FormFeedback,
} from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {} from //   setCurrentMovieStep,
// setFinalMovieData,
'../../../../app/slices/addMediaSlice';
import { RootState } from '../../../../app/store';
// import { useCreateMovieMutation } from '../../../../app/services/movieServerApi';
import Swal from 'sweetalert2';
import { ILoginFormError } from '../../../../interfaces/forms';
import { IStep9FormInput } from '../../../../interfaces/forms/AutographForm';
import { usePatchAutographMutation } from '../../../../app/services/autographServerApi';
import { setAuthenticatorId, setCurrentAutographStep } from '../../../../app/slices/addAutographSlice';

export const Step9 = () => {

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IStep9FormInput>();

  const [patchAutograph, { isLoading, isError, isSuccess, error }] =
    usePatchAutographMutation();

  const authenticators = useAppSelector(
    (state: RootState) => state.addAutograph.authenticators,
  );

  {
    isLoading &&
      Swal.fire({
        title: 'Loading',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
  }

  {
    isSuccess &&
      // Swal.fire({
      //   title: 'Logged in succesfully',
      //   text: 'Redirecting to home page',
      //   icon: 'success',
      //   timer: 1000,
      //   showConfirmButton: false,
      // });
      Swal.close();
  }

  const isLoginError = (error: any): error is ILoginFormError =>
    'status' in error;

  {
    isError &&
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        confirmButtonColor: 'green',
        footer: isLoginError(error)
          ? (error as ILoginFormError).data?.Error ||
            (error as ILoginFormError).error
          : 'An error ocurred',
      });
  }

  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<IStep9FormInput> = (formdata) => {
    // dispatch(setFinalMovieData(formdata));
    // const fullMovieData = { ...generalMovieData, ...secondaryMovieData, ...castCrewMovieData, ...imagesMovieData, ...languagesMovieData, ...chaptersMovieData, ...formdata, mode: "new"}
    // // console.log('DATA MOVIE STEP 10:', fullMovieData);
    // createMovie(fullMovieData)
    formdata.step = 'authenticator_data';
    patchAutograph(formdata).then(function (result: any) {
      if (result && result.data) {
        // dispatch(setNewMovieId(result.data.movieId));
        // dispatch(setCurrentMovieStep(10));
        console.log("form data test:", formdata)
        dispatch(setAuthenticatorId(formdata.authenticator.id))
        dispatch(setCurrentAutographStep(10));
      }
    });
  };

  return (
    <>
      <div className="media-section__subtitle">
        <h2 className="media-h2">Step 9</h2>
      </div>
      <div className="big-form-container">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label for="authenticator">Authenticator *</Label>
                <Controller
                  name="authenticator"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormSelect
                      field={field}
                      options={authenticators}
                      placeholder="Select the authenticator"
                      error={errors.authenticator}
                    />
                  )}
                />
                {errors.authenticator && (
                  <div className="invalid-message">
                    {'The authenticator is required'}
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label for="coaCode">COA Code *</Label>
                <Controller
                  name="coaCode"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="coaCode"
                      placeholder="Enter the coa code"
                      type="text"
                      invalid={!!errors.coaCode}
                      {...field}
                    />
                  )}
                />
                <FormFeedback>{'The coa code is required'}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <FormGroup>
                <Label for="coaUrl">COA URL</Label>
                <Controller
                  name="coaUrl"
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <Input
                      id="coaUrl"
                      placeholder="Enter the coa url"
                      type="text"
                      invalid={!!errors.coaUrl}
                      {...field}
                    />
                  )}
                />
              </FormGroup>
            </Col>
          </Row>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="submit">Submit</Button>
          </div>
        </Form>
      </div>
    </>
  );
};
