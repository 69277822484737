import '../AddMedia.scss';
import { Step1, Step2, Step3, Step4 } from './Steps';
import { RootState } from '../../../app/store';
import { useAppSelector } from '../../../app/hooks';

export const AddAutographOrder = () => {
  const currentStep = useAppSelector(
    (state: RootState) => state.addAutographOrder.currentAutographOrderStep,
  );
  return (
    <div className="add-movie-container">
      {currentStep === 1 && <Step1 />}
      {currentStep === 2 && <Step2 />}
      {currentStep === 3 && <Step3 />}
      {currentStep === 4 && <Step4 />}
    </div>
  );
};
