import { createSlice } from '@reduxjs/toolkit';
// import { extendedApiSlice } from '../services/movieServerApi'
export interface AddAutographState {
  currentAutographStep: number | null;
  selectedMediaType: string;
  selectedDataSource: string | null;
  selectedItunesResult: any;
  selectedImdbResult: any;
  selectedItunesCountry: string | null;
  generalAutographData: any;
  secondaryAutographData: any;
  castCrewMovieData: any;
  imagesMovieData: any;
  languagesMovieData: any;
  chaptersMovieData: any;
  finalMovieData: any;
  currencies: any[];
  sizes: any[];
  orientations: any[];
  documentTypes: any[];
  imageTypes: any[];
  videoTypes: any[];
  authenticators: any[];
  tags: any[];
  collections: any[];
  selectedArtistsData: any;
  newAutographId: string;
  selectedOrderId: string;
  selectedMovieResult: any;
  selectedTvShowResult: any;
  autographArtistsData: any;
  hasAuthentication: boolean;
  authenticatorId: string;
  accessLevels: any[];
}

const initialState: AddAutographState = {
  currentAutographStep: 1,
  selectedMediaType: '',
  selectedDataSource: null,
  selectedItunesResult: null,
  selectedImdbResult: null,
  selectedItunesCountry: null,
  generalAutographData: null,
  secondaryAutographData: null,
  castCrewMovieData: null,
  imagesMovieData: null,
  languagesMovieData: null,
  chaptersMovieData: { hasNamedChapters: false },
  finalMovieData: { editingCompleted: false, comments: '' },
  currencies: [],
  sizes: [],
  orientations: [],
  documentTypes: [],
  imageTypes: [],
  videoTypes: [],
  authenticators: [],
  tags: [],
  collections: [],
  selectedArtistsData: null,
  newAutographId: '',
  selectedOrderId: '',
  selectedMovieResult: null,
  selectedTvShowResult: null,
  authenticatorId: '',
  autographArtistsData: null,
  hasAuthentication: false,
  accessLevels: [],
};

export const addAutograph = createSlice({
  name: 'addAutograph',
  initialState,
  reducers: {
    setCurrentAutographStep: (state, { payload }) => {
      state.currentAutographStep = payload;
    },
    setAutographData: (state, { payload }) => {
      state.currencies = payload.currencies;
      state.sizes = payload.sizes;
      state.orientations = payload.orientations;
      state.documentTypes = payload.documentTypes;
      state.imageTypes = payload.imageTypes;
      state.videoTypes = payload.videoTypes;
      state.authenticators = payload.authenticators;
      state.tags = payload.tags;
      state.collections = payload.collections;
      state.accessLevels = payload.accessLevels;
    },
    setSecondaryAutographData: (state, { payload }) => {
      state.secondaryAutographData = payload;
    },
    setSelectedOrderId: (state, { payload }) => {
      state.selectedOrderId = payload;
    },
    setGeneralAutographData: (state, { payload }) => {
      state.generalAutographData = payload;
    },
    setImagesMovieData: (state, { payload }) => {
      state.imagesMovieData = payload;
    },
    setLanguagesMovieData: (state, { payload }) => {
      state.languagesMovieData = payload;
    },
    setChaptersMovieData: (state, { payload }) => {
      state.chaptersMovieData = payload;
    },
    setFinalMovieData: (state, { payload }) => {
      state.finalMovieData = payload;
    },
    setNewAutographId: (state, { payload }) => {
      state.newAutographId = payload;
    },
    setAuthenticatorId: (state, { payload }) => {
      state.authenticatorId = payload;
    },
    setSelectedMediaType: (state, { payload }) => {
      state.selectedMediaType = payload;
    },
    setHasAuthentication: (state, { payload }) => {
      state.hasAuthentication = payload;
    },
    setSelectedArtistsData: (state, { payload }) => {
      state.selectedArtistsData = payload;
    },
    setSelectedMovieResult: (state, { payload }) => {
      state.selectedMovieResult = payload;
    },
    setSelectedTvShowResult: (state, { payload }) => {
      state.selectedTvShowResult = payload;
    },
    setAutographArtistsData: (state, { payload }) => {
      state.autographArtistsData = payload;
    },
    resetAutographState: () => initialState,
  },
});

export const {
  setCurrentAutographStep,
  setSecondaryAutographData,
  setAutographData,
  setGeneralAutographData,
  setImagesMovieData,
  setLanguagesMovieData,
  setChaptersMovieData,
  setFinalMovieData,
  setNewAutographId,
  setSelectedOrderId,
  setSelectedArtistsData,
  setSelectedMediaType,
  setSelectedMovieResult,
  setSelectedTvShowResult,
  setAutographArtistsData,
  setHasAuthentication,
  resetAutographState,
  setAuthenticatorId,
} = addAutograph.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state: RootState) => state.counter.value;

export default addAutograph.reducer;
