import { mediaManagerServerApi } from './mediaManagerServerApi';
// import { navigationSlice } from '../slices/navigationSlice';
// import { setMoviePageStatus } from '../slices/navigationSlice';
// import { useAppDispatch } from '../hooks';

// const dispatch = useAppDispatch();

export const extendedApiSlice = mediaManagerServerApi.injectEndpoints({
  endpoints: (builder) => ({
    // getMovies: builder.query<any, any | void>({
    //   query: (object) =>
    //     `movie/movies/?page=${object?.page || 1}&keyword=${object?.keyword || ''}&tag=${object?.tag || ''}&collection=${object?.collection || ''}&studio=${object?.studio || ''}`, //&page_size=12
    //   // providesTags: ['Movie'],
    //   // providesTags: (result = [], error, arg) => [

    //   // async onQueryStarted(id, { dispatch, queryFulfilled }) {
    //   //   // `onStart` side-effect
    //   //   // dispatch(messageCreated('Fetching post...'))
    //   //   try {
    //   //     const { data } = await queryFulfilled
    //   //     // `onSuccess` side-effect
    //   //     dispatch(setMoviePageStatus({currentPage: data.currentPage, totalPages: data.totalPages}))
    //   //   } catch (err) {
    //   //     // `onError` side-effect
    //   //     // dispatch(messageCreated('Error fetching post!'))
    //   //   }
    //   // },

    //   transformResponse: (rawResult: {
    //     results: any;
    //     currentPage: number;
    //     totalPages: number;
    //   }) => {
    //     // // mediaManagerServerApi.
    //     // console.log('RAW RESPONSE:', rawResult);
    //     // // console.log("CURRENT PAGE:", rawResult.currentPage)
    //     // // console.log("TOTAL PAGES:", rawResult.totalPages)
    //     // // dispatch(setMoviePageStatus({currentPage: rawResult.currentPage, totalPages: rawResult.totalPages}))
    //     // return rawResult.results;
    //     console.log('I ENTERED THE TRANSFORM');
    //     return {
    //       results: rawResult.results,
    //       currentPage: rawResult.currentPage,
    //       totalPages: rawResult.totalPages,
    //     };
    //   },

    //   // providesTags: (result = {}) => [
    //   //   'Movie',
    //   //   ...result?.results?.map(({ id }: { id: number }) => ({
    //   //     type: 'Movie',
    //   //     id,
    //   //   })),
    //   // ],

    //   // providesTags: (result) =>
    //   //   result
    //   //     ? [
    //   //         ...result.results.map(({ id }: { id: number }) => ({ type: 'Movies' as const, id })),
    //   //         { type: 'Movies', id: 'LIST' },
    //   //       ]
    //   //     : [{ type: 'Movies', id: 'LIST' }],

    //   // providesTags: (result, error, arg) =>
    //   providesTags: (result) =>
    //     result
    //       ? [
    //           ...result.results.map(({ id }: { id: number }) => ({
    //             type: 'Movie' as const,
    //             id,
    //           })),
    //           'Movie',
    //         ]
    //       : ['Movie'],

    //   // serializeQueryArgs: ({ endpointName }) => {
    //   //   return endpointName
    //   // },
    //   serializeQueryArgs: ({ queryArgs }) => {
    //     // console.log("serializeQueryArgs");
    //     console.log('QUERY ARGS:', queryArgs);
    //     if (queryArgs) {
    //       const { page, ...otherArgs } = queryArgs;
    //       console.log('PAGE:', page);
    //       // console.log("OTHER ARGS:", otherArgs)
    //       return otherArgs;
    //       // return page
    //       // return queryArgs
    //     } else {
    //       return queryArgs;
    //     }
    //   },
    //   // Always merge incoming data to the cache entry
    //   merge: (currentCache, newItems) => {
    //     console.log('currentCache:', currentCache);
    //     console.log('newItems:', newItems);

    //     //#1
    //     currentCache.results.push(...newItems.results);
    //     currentCache.currentPage = newItems.currentPage;
    //     //#2
    //     // if (newItems.currentPage > currentCache.currentPage) {
    //     //   currentCache.results.push(...newItems.results);
    //     //   currentCache.currentPage = newItems.currentPage;
    //     // } else {
    //     //   currentCache.results = newItems.results;
    //     //   currentCache.currentPage = newItems.currentPage;
    //     // }
    //   },
    //   // Refetch when the page arg changes
    //   forceRefetch({ currentArg, previousArg }) {
    //     console.log('currentArg:', currentArg);
    //     console.log('previousArg:', previousArg);
    //     // return currentArg !== previousArg
    //     console.log('CURRENT ARG PAGE:', currentArg?.page);
    //     console.log('PREVIOUS ARG PAGE:', previousArg?.page);
    //     console.log(
    //       'PAGES DIFF RESULT:',
    //       currentArg?.page > previousArg?.page,
    //     );
    //     // return currentArg?.page !== previousArg?.page;
    //     // return currentArg !== previousArg;
    //     //#1
    //     return currentArg?.page > previousArg?.page;
    //     //#2
    //     // return currentArg?.page !== previousArg?.page;
    //   },
    // }),
    // getMovie: builder.query<any, string | undefined>({
    //   query: (id) => `movie/movies/${id}/`,
    //   // providesTags: ['Movie'],
    //   providesTags: (result, error, arg) => [{ type: 'Movie', id: arg }],
    // }),

    getImdbArtistOptions: builder.query<any, any | void>({
      query: (object) =>
        `artists/get_imdb_artist_options/?artist_name=${object.artistName}`,
      // transformResponse: (rawResult: {
      //   imdbSearchArtistResults: any;
      // }) => {
      //   const processedImdbSearchResults =
      //     rawResult.imdbSearchArtistResults.map((searchResult: any) => {
      //       return {
      //         title: searchResult.Title,
      //         cover: searchResult.Poster,
      //         id: searchResult.imdbID,
      //       };
      //     });
      //   return {
      //     imdbSearchMovieResults: processedImdbSearchResults,
      //   };
      // },
    }),
  }),
});

export const {
  // useGetMoviesQuery,
  // useGetMovieQuery,
  useGetImdbArtistOptionsQuery,
} = extendedApiSlice;
