import '../AddMedia.scss';
import {
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Step6,
  Step7,
  Step8,
  Step9,
  Step10,
  Step11,
  Step12,
  Step13,
  Step14,
  Step15,
  Step16,
  Step17,
} from './Steps';
import { RootState } from '../../../app/store';
import { useAppSelector } from '../../../app/hooks';

export const AddAutograph = () => {
  const currentStep = useAppSelector(
    (state: RootState) => state.addAutograph.currentAutographStep,
  );
  return (
    <div className="add-movie-container">
      {currentStep === 1 && <Step1 />}
      {currentStep === 2 && <Step2 />}
      {currentStep === 3 && <Step3 />}
      {currentStep === 4 && <Step4 />}
      {currentStep === 5 && <Step5 />}
      {currentStep === 6 && <Step6 />}
      {currentStep === 7 && <Step7 />}
      {currentStep === 8 && <Step8 />}
      {currentStep === 9 && <Step9 />}
      {currentStep === 10 && <Step10 />}
      {currentStep === 11 && <Step11 />}
      {currentStep === 12 && <Step12 />}
      {currentStep === 13 && <Step13 />}
      {currentStep === 14 && <Step14 />}
      {currentStep === 15 && <Step15 />}
      {currentStep === 16 && <Step16 />}
      {currentStep === 17 && <Step17 />}
    </div>
  );
};
