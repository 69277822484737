import { LoadingSpinner } from '../../../../components';
import '../../AddMedia.scss';
import { useGetAutographArtistsDataQuery } from '../../../../app/services/autographServerApi';
import errorIcon from '../../../../assets/error.svg';
import { useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { ArtistsForm } from './ArtistsForm';
import { useEffect } from 'react';

export const Step7 = () => {
  const {
    // data = {},
    isSuccess,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetAutographArtistsDataQuery({
    selectedMovieResult: useAppSelector(
      (state: RootState) => state.addAutograph.selectedMovieResult,
    ),
    selectedTvShowResult: useAppSelector(
      (state: RootState) => state.addAutograph.selectedTvShowResult,
    ),
  });

  useEffect(() => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: 'instant',
    });
  }, []);
  return (
    <>
      <div className="media-section__subtitle">
        <h2 className="media-h2">Step 7</h2>
      </div>
      {isLoading || isFetching ? (
        <div className="list-no-item-add">
          <LoadingSpinner />
        </div>
      ) : isError ? (
        <div className="list-no-item-add">
          <img src={errorIcon} alt="error-icon" />
          <p>{error?.error}</p>
        </div>
      ) : isSuccess ? (
        <ArtistsForm />
      ) : null}
    </>
  );
};
