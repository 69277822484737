import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import {
  Col,
  Form,
  FormGroup,
  Label,
  Row,
  FormFeedback,
  Input,
} from 'reactstrap';
import { Button } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import { ILoginFormError } from '../../../../interfaces/forms';
import { IStep7FormInput } from '../../../../interfaces/forms/AutographForm';
// import { setAutographArtistsData, setCurrentAutographStep } from '../../../../app/slices/addAutographSlice';
import { setCurrentAutographStep } from '../../../../app/slices/addAutographSlice';
import { usePatchAutographMutation } from '../../../../app/services/autographServerApi';

export const ArtistsForm = () => {
  useEffect(() => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: 'instant',
    });
  }, []);
  const dispatch = useAppDispatch();
  const autographArtistsData = useAppSelector(
    (state: RootState) => state.addAutograph.autographArtistsData,
  );

  const selectedMovieResult = useAppSelector(
    (state: RootState) => state.addAutograph.selectedMovieResult,
  );
  const selectedTvShowResult = useAppSelector(
    (state: RootState) => state.addAutograph.selectedTvShowResult,
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IStep7FormInput>({
    defaultValues: autographArtistsData,
  });

  const { fields: artistFields } = useFieldArray({ control, name: 'artists' });

  const [patchAutograph, { isLoading, isError, isSuccess, error }] =
    usePatchAutographMutation();

  const onSubmit: SubmitHandler<IStep7FormInput> = (data) => {
    console.log('DATA MOVIE STEP 6:', data);
    // dispatch(setAutographArtistsData(data));
    data.step = 'autograph_media_artists_data';
    data.movieId =  selectedMovieResult?.id
    data.tvShowId = selectedTvShowResult?.id
    console.log("DATA:", data)
    patchAutograph(data).then(function (result: any) {
      if (result && result.data) {
        dispatch(setCurrentAutographStep(8));
      }
    });
  };

  console.log('DA ERROR:', error);

  {
    isLoading &&
      Swal.fire({
        title: 'Loading',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
  }

  console.log('IS LOADING:', isLoading);

  {
    isSuccess &&
      // Swal.fire({
      //   title: 'Logged in succesfully',
      //   text: 'Redirecting to home page',
      //   icon: 'success',
      //   timer: 1500,
      //   showConfirmButton: false,
      // });
      Swal.close();
  }

  const isLoginError = (error: any): error is ILoginFormError =>
    'status' in error;

  {
    isError &&
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        confirmButtonColor: 'green',
        footer: isLoginError(error)
          ? (error as ILoginFormError).data?.Error ||
            (error as ILoginFormError).error
          : 'An error ocurred',
      });
  }
  return (
    <>
      <div className="big-form-container">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <h2 className="media-h2">Artists</h2>
          {artistFields.map((field, index) => (
            <Row key={field.id}>
              <Col md={6}>
                <FormGroup>
                  <Label for="artistName">Artist Name *</Label>
                  <Controller
                    name={`artists.${index}.artistName`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="artistName"
                        placeholder="Enter the artist's name"
                        type="text"
                        invalid={!!errors.artists?.[index]}
                        disabled
                        {...field}
                      />
                    )}
                  />
                  <FormFeedback>
                    {"The artist's name is required"}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="role">Role *</Label>
                  <Controller
                    name={`artists.${index}.role`}
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <Input
                        id="role"
                        placeholder="Enter the artist's role"
                        type="text"
                        invalid={!!errors.artists?.[index]}
                        {...field}
                      />
                    )}
                  />
                  <FormFeedback>
                    {"The artist's role is required"}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          ))}

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="submit">Submit</Button>
          </div>
        </Form>
      </div>
    </>
  );
};
