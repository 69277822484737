import { Button, FormSelect, FormFileInput } from '../../../../components';
import '../../AddMedia.scss';
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from 'react-hook-form';
import {
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  Button as ButtonRs,
  Input,
} from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import Swal from 'sweetalert2';
import { ILoginFormError } from '../../../../interfaces/forms';
import { usePatchAutographMutation } from '../../../../app/services/autographServerApi';
import { setCurrentAutographStep } from '../../../../app/slices/addAutographSlice';
import { IStep14FormInput } from '../../../../interfaces/forms/AutographForm';
import { useState } from 'react';

export const Step14 = () => {
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IStep14FormInput>({});

  const {
    fields: imageFields,
    append: imageAppend,
    remove: imageRemove,
  } = useFieldArray({ control, name: 'images' });

  const [showImageMinimumError, setShowImageMinimumError] =
    useState<boolean>(false);
  const [showImageCheckedError, setShowImageCheckedError] =
    useState<boolean>(false);

  const [patchAutograph, { isLoading, isError, isSuccess, error }] =
    usePatchAutographMutation();

  const onSubmit: SubmitHandler<IStep14FormInput> = (data) => {
    setShowImageMinimumError(false);
    setShowImageCheckedError(false);
    let imageError = false;
    if (data.images.length === 0) {
      setShowImageMinimumError(true);
      imageError = true;
    } else if (data.images.filter((image) => image.main).length !== 1) {
      setShowImageCheckedError(true);
      imageError = true;
    }

    if (!imageError) {
      console.log('DATA MOVIE STEP 8:', data);
      // dispatch(setLanguagesMovieData(data));
      // dispatch(setCurrentMovieStep(9));
      data.step = 'images_data';

      patchAutograph(data).then(function (result: any) {
        if (result && result.data) {
          dispatch(setCurrentAutographStep(15));
        }
      });
    }
  };
  const imageTypes = useAppSelector(
    (state: RootState) => state.addAutograph.imageTypes,
  );

  console.log("THE ERRORS I'M LOOKING FOR:", errors);

  {
    isLoading &&
      Swal.fire({
        title: 'Loading',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
  }

  {
    isSuccess &&
      // Swal.fire({
      //   title: 'Logged in succesfully',
      //   text: 'Redirecting to home page',
      //   icon: 'success',
      //   timer: 1000,
      //   showConfirmButton: false,
      // });
      Swal.close();
  }

  const isLoginError = (error: any): error is ILoginFormError =>
    'status' in error;

  {
    isError &&
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        confirmButtonColor: 'green',
        footer: isLoginError(error)
          ? (error as ILoginFormError).data?.Error ||
            (error as ILoginFormError).error
          : 'An error ocurred',
      });
  }

  return (
    <>
      <div className="media-section__subtitle">
        <h2 className="media-h2">Step 14</h2>
      </div>
      <div className="big-form-container">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <h2 className="media-h2">Images</h2>
          {imageFields.map((field, index) => (
            <Row key={field.id}>
              <Col md={5}>
                <FormGroup>
                  <Label for="type">Type *</Label>
                  <Controller
                    // key={field.id}
                    name={`images.${index}.type`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FormSelect
                        field={field}
                        options={imageTypes}
                        placeholder="Select the image's type"
                        error={errors.images?.[index]}
                      />
                    )}
                  />

                  {errors.images?.[index] && (
                    <div className="invalid-message">
                      {"The image's type is required"}
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col md={5}>
                <FormGroup>
                  <Label for="file">File *</Label>
                  <FormFileInput
                    control={control}
                    name={`images.${index}.file`}
                    id="file"
                    placeholder={'Select the file'}
                    required={true}
                    error={errors.images?.[index]}
                    accept=".jpg,.png"
                  />
                  {errors.images?.[index] && (
                    <div className="invalid-message">
                      {'The file is required'}
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col md={1}>
                <FormGroup
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Label for="main">Main?</Label>
                  <Controller
                    // key={field.id}
                    name={`images.${index}.main`}
                    control={control}
                    // rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        type="checkbox"
                        style={{ marginTop: '10px' }}
                        // {...field}
                        // defaultChecked={field.value}
                        checked={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </FormGroup>
              </Col>

              <Col md={1}>
                <ButtonRs
                  type="button"
                  color="danger"
                  onClick={() => {
                    imageRemove(index);
                  }}
                >
                  X
                </ButtonRs>
              </Col>
            </Row>
          ))}

          {showImageMinimumError && (
            <div className="invalid-message">
              {'At least one image must be added'}
            </div>
          )}
          {showImageCheckedError && (
            <div className="invalid-message">
              {'One image must be selected as the main one'}
            </div>
          )}

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="button"
              onClick={() => {
                imageAppend({
                  type: null,
                  main: false,
                });
              }}
            >
              Add Image
            </Button>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="submit">Submit</Button>
          </div>
        </Form>
      </div>
    </>
  );
};
