import { LoadingSpinner } from '../../../../components';
import '../../AddMedia.scss';
import errorIcon from '../../../../assets/error.svg';
import { AutographOrderForm } from './AutographOrderForm';
import { useEffect } from 'react';
import { useGetAutographOrderMediaDataQuery } from '../../../../app/services/autographServerApi';

export const Step1 = () => {
  const { isSuccess, isLoading, isFetching, isError, error } =
    useGetAutographOrderMediaDataQuery({});

  useEffect(() => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: 'instant',
    });
  }, []);
  return (
    <>
      {isLoading || isFetching ? (
        <div className="list-no-item-add">
          <LoadingSpinner />
        </div>
      ) : isError ? (
        <div className="list-no-item-add">
          <img src={errorIcon} alt="error-icon" />
          <p>{error?.error}</p>
        </div>
      ) : isSuccess ? (
        <AutographOrderForm />
      ) : null}
    </>
  );
};
