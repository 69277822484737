import { Button } from '../../../../components';
import '../../AddMedia.scss';
import { useAppDispatch } from '../../../../app/hooks';
import {
  setCurrentAutographStep,
  setSelectedMediaType,
} from '../../../../app/slices/addAutographSlice';

export const Step5 = () => {
  const dispatch = useAppDispatch();
  const setSelectedOption = (selectedOption: string) => {
    dispatch(setSelectedMediaType(selectedOption));
    if (selectedOption === 'none') {
      dispatch(setCurrentAutographStep(8));
    } else {
      dispatch(setCurrentAutographStep(6));
    }
  };

  return (
    <>
      <div className="media-section__subtitle">
        <h2 className="media-h2">
          {"Step 5: Select the Autograph's Associated Media"}
        </h2>
      </div>
      <div className="buttons-container">
        <Button onClick={() => setSelectedOption('movie')}>
          <i className="bi bi-film"></i> Movie
        </Button>
        <Button onClick={() => setSelectedOption('tvShow')}>
          <i className="bi bi-tv"></i> TV Show
        </Button>
        <Button onClick={() => setSelectedOption('none')}>
          <i className="bi bi-tv"></i> None
        </Button>
      </div>
    </>
  );
};
