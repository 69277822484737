import { Button, FormSelect } from '../../../../components';
import '../../AddMedia.scss';
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from 'react-hook-form';
import {
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  Button as ButtonRs,
} from 'reactstrap';
import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { IStep3FormInput } from '../../../../interfaces/forms/AutographForm';
import {
  setCurrentAutographStep,
  setSecondaryAutographData,
} from '../../../../app/slices/addAutographSlice';

export const Step3 = () => {
  useEffect(() => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: 'instant',
    });
  }, []);
  const dispatch = useAppDispatch();
  // const secondaryAutographData = useAppSelector(
  //   (state: RootState) => state.addAutograph.secondaryAutographData,
  // );

  const {
    control,
    handleSubmit,
    // register,
    // reset,
    formState: { errors },
  } = useForm<IStep3FormInput>({
    // defaultValues: secondaryAutographData,
  });

  const {
    fields: tagFields,
    append: tagAppend,
    remove: tagRemove,
  } = useFieldArray({ control, name: 'tags', rules: { minLength: 1 } });

  const {
    fields: collectionFields,
    append: collectionAppend,
    remove: collectionRemove,
  } = useFieldArray({ control, name: 'collections', rules: { minLength: 1 } });

  const onSubmit: SubmitHandler<IStep3FormInput> = (data) => {
    console.log('DATA MOVIE STEP 3:', data);
    dispatch(setSecondaryAutographData(data));
    dispatch(setCurrentAutographStep(4));
  };

  const tags = useAppSelector((state: RootState) => state.addAutograph.tags);
  const collections = useAppSelector(
    (state: RootState) => state.addAutograph.collections,
  );
  console.log("THE ERRORS I'M LOOKING FOR:", errors);

  return (
    <>
      <div className="media-section__subtitle">
        <h2 className="media-h2">Step 3</h2>
      </div>
      <div className="big-form-container">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <h2 className="media-h2">Tags</h2>
          {tagFields.map((field, index) => (
            <Row key={field.id}>
              <Col md={11}>
                <FormGroup>
                  <Label for="tag">Tag</Label>
                  <Controller
                    name={`tags.${index}`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FormSelect
                        field={field}
                        options={tags}
                        placeholder="Select the autograph's tag"
                        error={errors.tags?.[index]}
                      />
                    )}
                  />

                  {errors.tags?.[index] && (
                    <div className="invalid-message">
                      {"The autograph's tag is required"}
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col md={1}>
                <ButtonRs
                  type="button"
                  color="danger"
                  onClick={() => {
                    tagRemove(index);
                  }}
                >
                  X
                </ButtonRs>
              </Col>
            </Row>
          ))}

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="button"
              onClick={() => {
                tagAppend({ id: null, label: null });
              }}
            >
              Add Tag
            </Button>
          </div>

          <h2 className="media-h2">Collections</h2>
          {collectionFields.map((field, index) => (
            <Row key={field.id}>
              <Col md={11}>
                <FormGroup>
                  <Label for="collection">Collection</Label>
                  <Controller
                    // key={field.id}
                    name={`collections.${index}`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FormSelect
                        field={field}
                        options={collections}
                        placeholder="Select the autograph's collection"
                        error={errors.collections?.[index]}
                      />
                    )}
                  />

                  {errors.collections?.[index] && (
                    <div className="invalid-message">
                      {"The autograph's collection is required"}
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col md={1}>
                <ButtonRs
                  type="button"
                  color="danger"
                  onClick={() => {
                    collectionRemove(index);
                  }}
                >
                  X
                </ButtonRs>
              </Col>
            </Row>
          ))}

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="button"
              onClick={() => {
                collectionAppend({ id: null, label: null });
              }}
            >
              Add Collection
            </Button>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="submit">Submit</Button>
          </div>
        </Form>
      </div>
    </>
  );
};
