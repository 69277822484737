import { createSlice } from '@reduxjs/toolkit';
// import { extendedApiSlice } from '../services/movieServerApi'
export interface AddAutographOrderState {
  currentAutographOrderStep: number | null;
  selectedAddType: string | null;
  selectedDataSource: string | null;
  selectedItunesResult: any;
  selectedImdbResult: any;
  selectedItunesCountry: string | null;
  generalAutographOrderData: any;
  secondaryMovieData: any;
  castCrewMovieData: any;
  imagesMovieData: any;
  languagesMovieData: any;
  chaptersMovieData: any;
  finalMovieData: any;
  sellers: any[];
  stores: any[];
  documentTypes: any[];
  imageTypes: any[];
  newAutographOrderId: string;
}

const initialState: AddAutographOrderState = {
  currentAutographOrderStep: 1,
  selectedAddType: null,
  selectedDataSource: null,
  selectedItunesResult: null,
  selectedImdbResult: null,
  selectedItunesCountry: null,
  generalAutographOrderData: null,
  secondaryMovieData: null,
  castCrewMovieData: null,
  imagesMovieData: null,
  languagesMovieData: null,
  chaptersMovieData: { hasNamedChapters: false },
  finalMovieData: { editingCompleted: false, comments: '' },
  sellers: [],
  stores: [],
  documentTypes: [],
  imageTypes: [],
  newAutographOrderId: '',
};

export const addAutographOrder = createSlice({
  name: 'addAutographOrder',
  initialState,
  reducers: {
    setCurrentAutographOrderStep: (state, { payload }) => {
      state.currentAutographOrderStep = payload;
    },
    setAutographOrderData: (state, { payload }) => {
      state.sellers = payload.sellers;
      state.stores = payload.stores;
      state.documentTypes = payload.documentTypes;
      state.imageTypes = payload.imageTypes;
    },
    setGeneralAutographOrderData: (state, { payload }) => {
      state.generalAutographOrderData = payload;
    },
    setImagesMovieData: (state, { payload }) => {
      state.imagesMovieData = payload;
    },
    setLanguagesMovieData: (state, { payload }) => {
      state.languagesMovieData = payload;
    },
    setChaptersMovieData: (state, { payload }) => {
      state.chaptersMovieData = payload;
    },
    setFinalMovieData: (state, { payload }) => {
      state.finalMovieData = payload;
    },
    setNewAutographOrderId: (state, { payload }) => {
      state.newAutographOrderId = payload;
    },
    resetAutographOrderState: () => initialState,
  },
});

export const {
  setCurrentAutographOrderStep,
  setAutographOrderData,
  setGeneralAutographOrderData,
  setImagesMovieData,
  setLanguagesMovieData,
  setChaptersMovieData,
  setFinalMovieData,
  setNewAutographOrderId,
  resetAutographOrderState,
} = addAutographOrder.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state: RootState) => state.counter.value;

export default addAutographOrder.reducer;
