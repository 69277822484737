import { Button, PageHeader } from '../../components';
import './AddMedia.scss';
import { AddMovie } from './Movie';
import { AddTVShow } from './TVShow';
import { AddTVShowSeason } from './TVShowSeason';
import { AddTVShowSeasonEpisode } from './TVShowSeasonEpisode';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { setSelectedMediaType } from '../../app/slices/addMediaSlice';
import { AddAutographOrder } from './AutographOrder';
import { AddAutograph } from './Autograph';

export const AddMedia = () => {
  const selectedMediaType = useAppSelector(
    (state: RootState) => state.addMedia.selectedMediaType,
  );

  // const [selectedMediaType, setSelectedMediaType] = useState<string | null>(
  //   null,
  // );
  console.log('SELECTED MEDIA TYPE:', selectedMediaType);
  const dispatch = useAppDispatch();
  const pageHeaderTitle = () => {
    if (selectedMediaType === 'movie') {
      return 'Add Movie';
    } else if (selectedMediaType === 'tvShow') {
      return 'Add TV Show';
    } else if (selectedMediaType === 'tvShowSeason') {
      return 'Add TV Show Season';
    } else if (selectedMediaType === 'tvShowSeasonEpisode') {
      return 'Add TV Show Season Episode';
    } else if (selectedMediaType === 'autographOrder') {
      return 'Add Autograph Order';
    } else if (selectedMediaType === 'autograph') {
      return 'Add Autograph';
    } else {
      return 'Add Media';
    }
  };

  return (
    <>
      <PageHeader title={pageHeaderTitle()} />
      <div className="add-media-form-container">
        {!selectedMediaType && (
          <div className="buttons-container">
            <Button onClick={() => dispatch(setSelectedMediaType('movie'))}>
              <i className="bi bi-film"></i> Movie
            </Button>
            <Button onClick={() => dispatch(setSelectedMediaType('tvShow'))}>
              <i className="bi bi-tv"></i> TV Show
            </Button>
            <Button
              onClick={() => dispatch(setSelectedMediaType('tvShowSeason'))}
            >
              <i className="bi bi-1-square"></i> TV Show Season
            </Button>
            <Button
              onClick={() =>
                dispatch(setSelectedMediaType('tvShowSeasonEpisode'))
              }
            >
              <i className="bi bi-file-earmark-play"></i> TV Show Season
              Episode
            </Button>
            <Button
              onClick={() => dispatch(setSelectedMediaType('autographOrder'))}
            >
              <i className="bi bi-pencil-square"></i> Autograph Order
            </Button>
            <Button
              onClick={() => dispatch(setSelectedMediaType('autograph'))}
            >
              <i className="bi bi-pencil"></i> Autograph
            </Button>
          </div>
        )}
        {selectedMediaType === 'movie' && <AddMovie />}
        {selectedMediaType === 'tvShow' && <AddTVShow />}
        {selectedMediaType === 'tvShowSeason' && <AddTVShowSeason />}
        {selectedMediaType === 'tvShowSeasonEpisode' && (
          <AddTVShowSeasonEpisode />
        )}
        {selectedMediaType === 'autographOrder' && <AddAutographOrder />}
        {selectedMediaType === 'autograph' && <AddAutograph />}
      </div>
    </>
  );
};
