import { Button, LoadingSpinner } from '../../../../components';
import '../../AddMedia.scss';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { useGetAutographOrderQuery } from '../../../../app/services/autographServerApi';
import { useState } from 'react';
import errorIcon from '../../../../assets/error.svg';
import { IStep1FormInput } from '../../../../interfaces/forms/AutographForm';
import { useAppDispatch } from '../../../../app/hooks';
import {
  setCurrentAutographStep,
  setSelectedOrderId,
} from '../../../../app/slices/addAutographSlice';

export const Step1 = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IStep1FormInput>({
    defaultValues: {
      orderId: '',
    },
  });

  const [currentSearchParams, setCurrentSearchParams] = useState<any>({});
  const [skipGetAutographOrderQuery, setSkipGetAutographOrderQuery] =
    useState(true);
  const [showResultsContainer, setShowResultsContainer] = useState(false);

  const dispatch = useAppDispatch();

  const {
    data = {},
    isLoading,
    isFetching,
    isSuccess,
    isError,
    error,
  } = useGetAutographOrderQuery(currentSearchParams.orderId, {
    skip: skipGetAutographOrderQuery,
  });

  const onSubmit: SubmitHandler<IStep1FormInput> = (data) => {
    setShowResultsContainer(true);
    setCurrentSearchParams(data);
    setSkipGetAutographOrderQuery(false);
    console.log('DATA MOVIE STEP 1:', data);
  };

  const setAutographOrder = () => {
    dispatch(setSelectedOrderId(data.id));
    dispatch(setCurrentAutographStep(2));
  };

  {
    isSuccess && setAutographOrder();
  }

  return (
    <>
      <div className="media-section__subtitle">
        <h2 className="media-h2">Step 1</h2>
      </div>
      <div className="form-container">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label for="orderId">Order ID *</Label>
            <Controller
              name="orderId"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  id="orderId"
                  placeholder="Enter the order id"
                  type="text"
                  invalid={!!errors.orderId}
                  {...field}
                />
              )}
            />
            <FormFeedback>The order id is required</FormFeedback>
          </FormGroup>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="submit">Search</Button>
          </div>
        </Form>
      </div>
      {showResultsContainer && (
        <div className="media-section media-mt-1">
          {isLoading || isFetching ? (
            <div className="list-no-item-add">
              <LoadingSpinner />
            </div>
          ) : isError ? (
            <div className="list-no-item-add">
              <img src={errorIcon} alt="error-icon" />
              <p>{error?.error}</p>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};
