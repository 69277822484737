import { Button, FormSelect } from '../../../../components';
import '../../AddMedia.scss';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import {
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
} from 'reactstrap';
import Swal from 'sweetalert2';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { IStep1FormInput } from '../../../../interfaces/forms/AutographOrderForm';
import {
  setCurrentAutographOrderStep,
  setGeneralAutographOrderData,
  setNewAutographOrderId,
} from '../../../../app/slices/addAutographOrderSlice';
import { useCreateAutographOrderMutation } from '../../../../app/services/autographServerApi';
import { ILoginFormError } from '../../../../interfaces/forms';

export const AutographOrderForm = () => {
  const dispatch = useAppDispatch();

  const sellers = useAppSelector(
    (state: RootState) => state.addAutographOrder.sellers,
  );
  const stores = useAppSelector(
    (state: RootState) => state.addAutographOrder.stores,
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IStep1FormInput>({});

  const [createAutographOrder, { isLoading, isError, isSuccess, error }] =
    useCreateAutographOrderMutation();

  const onSubmit: SubmitHandler<IStep1FormInput> = (formdata) => {
    createAutographOrder(formdata).then(function (result: any) {
      if (result && result.data) {
        dispatch(setGeneralAutographOrderData(formdata));
        dispatch(setNewAutographOrderId(result.data.orderId));
        dispatch(setCurrentAutographOrderStep(2));
      }
    });
  };

  {
    isLoading &&
      Swal.fire({
        title: 'Loading',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
  }

  console.log('IS LOADING:', isLoading);

  {
    isSuccess &&
      // Swal.fire({
      //   title: 'Logged in succesfully',
      //   text: 'Redirecting to home page',
      //   icon: 'success',
      //   timer: 1500,
      //   showConfirmButton: false,
      // });
      Swal.close();
  }

  const isLoginError = (error: any): error is ILoginFormError =>
    'status' in error;

  {
    isError &&
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        confirmButtonColor: 'green',
        footer: isLoginError(error)
          ? (error as ILoginFormError).data?.Error ||
            (error as ILoginFormError).error
          : 'An error ocurred',
      });
  }

  return (
    <div className="big-form-container">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="seller">Seller *</Label>
              <Controller
                name="seller"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FormSelect
                    field={field}
                    options={sellers}
                    placeholder="Select the seller"
                    error={errors.seller}
                  />
                )}
              />
              {errors.seller && (
                <div className="invalid-message">
                  {'The seller is required'}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="store">Store *</Label>
              <Controller
                name="store"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FormSelect
                    field={field}
                    options={stores}
                    placeholder="Select the store"
                    error={errors.store}
                  />
                )}
              />
              {errors.store && (
                <div className="invalid-message">
                  {'The store is required'}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="dateOrdered">Date Ordered *</Label>
              <Controller
                name="dateOrdered"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="dateOrdered"
                    placeholder="Enter the date ordered"
                    type="date"
                    invalid={!!errors.dateOrdered}
                    {...field}
                  />
                )}
              />
              <FormFeedback>{'The date ordered is required'}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="dateReceived">Date Received</Label>
              <Controller
                name="dateReceived"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Input
                    id="dateReceived"
                    placeholder="Enter the date received"
                    type="date"
                    invalid={!!errors.dateReceived}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="placeOfOrigin">Place of Origin *</Label>
              <Controller
                name="placeOfOrigin"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="placeOfOrigin"
                    placeholder="Enter the place of origin"
                    type="text"
                    invalid={!!errors.placeOfOrigin}
                    {...field}
                  />
                )}
              />
              <FormFeedback>{'The place of origin is required'}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="comments">Comments</Label>
              <Controller
                name="comments"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Input
                    id="comments"
                    placeholder="Enter the comments"
                    type="textarea"
                    invalid={!!errors.comments}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button type="submit">Submit</Button>
        </div>
      </Form>
    </div>
  );
};
