import { Button, LoadingSpinner } from '../../../../components';
import '../../AddMedia.scss';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { useGetImdbArtistOptionsQuery } from '../../../../app/services/artistServerApi';
import { useState } from 'react';
import errorIcon from '../../../../assets/error.svg';

import { SearchResults } from './SearchResults';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IStep4FormInput } from '../../../../interfaces/forms/AutographForm/IStep4Form';
import {
  setCurrentAutographStep,
  setNewAutographId,
  setSelectedArtistsData,
} from '../../../../app/slices/addAutographSlice';
import { useCreateAutographMutation } from '../../../../app/services/autographServerApi';
import { RootState } from '../../../../app/store';
import Swal from 'sweetalert2';
import { ILoginFormError } from '../../../../interfaces/forms';

export const Step4 = () => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IStep4FormInput>({
    defaultValues: {
      artistName: '',
    },
  });

  const [currentSearchParams, setCurrentSearchParams] = useState({});
  const [skipGetArtistOptionsQuery, setSkipGetArtistOptionsQuery] =
    useState(true);

  const [selectedImdbResult, setSelectedImdbResult] = useState<any | null>(
    null,
  );
  const [showResultsContainer, setShowResultsContainer] = useState(false);

  const [selectedArtists, setSelectedArtists] = useState<any>([]);

  const {
    data: getArtistOptionsData = {},
    isLoading: isGetArtistOptionsLoading,
    isFetching: isGetArtistOptionsFetching,
    isError: isGetArtistOptionsError,
    error: getArtistOptionsError,
  } = useGetImdbArtistOptionsQuery(currentSearchParams, {
    skip: skipGetArtistOptionsQuery,
  });

  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<IStep4FormInput> = (data) => {
    // setSelectedItunesCountry(
    //   data.itunesCountry ? data.itunesCountry.value : null,
    // );
    setShowResultsContainer(true);
    setCurrentSearchParams(data);
    setSkipGetArtistOptionsQuery(false);
    console.log('DATA MOVIE STEP 2:', data);
  };

  const setSelectedArtist = () => {
    // dispatch(
    //   setSelectedItunesAndImdbResults({
    //     selectedImdbResult: selectedImdbResult.imdbId,
    //   }),
    // );
    // dispatch(setCurrentTvShowSeasonStep(5));
    console.log('SELECTED ARTISTS IDS:', selectedArtists);
    console.log('SELECTED IMDB RESULT:', selectedImdbResult);
    setSelectedArtists([
      ...selectedArtists,
      {
        artistName: selectedImdbResult.artistName,
        imdbId: selectedImdbResult.artistId,
        photoUrl: selectedImdbResult.imageUrl,
      },
    ]);
    reset();
    setSkipGetArtistOptionsQuery(true);
    setShowResultsContainer(false);
    clearSelectedOptions();
  };

  const [createAutograph, { isLoading, isError, isSuccess, error }] =
    useCreateAutographMutation();

  const { generalAutographData, secondaryAutographData, selectedOrderId } =
    useAppSelector((state: RootState) => state.addAutograph);

  const setArtistSearchData = () => {
    dispatch(setSelectedArtistsData(selectedArtists));

    const fullAutographData = {
      ...generalAutographData,
      ...secondaryAutographData,
      selectedArtists,
      orderId: selectedOrderId,
    };
    // dispatch(setCurrentMovieStep(7));

    createAutograph(fullAutographData).then(function (result: any) {
      if (result && result.data) {
        dispatch(setNewAutographId(result.data.autographId));
        dispatch(setCurrentAutographStep(5));
      }
    });
  };

  const clearSelectedOptions = () => {
    setSelectedImdbResult(null);
  };

  const enableButton = selectedImdbResult;

  {
    isLoading &&
      Swal.fire({
        title: 'Loading',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
  }

  console.log('IS LOADING:', isLoading);

  {
    isSuccess &&
      // Swal.fire({
      //   title: 'Logged in succesfully',
      //   text: 'Redirecting to home page',
      //   icon: 'success',
      //   timer: 1500,
      //   showConfirmButton: false,
      // });
      Swal.close();
  }

  const isLoginError = (error: any): error is ILoginFormError =>
    'status' in error;

  {
    isError &&
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        confirmButtonColor: 'green',
        footer: isLoginError(error)
          ? (error as ILoginFormError).data?.Error ||
            (error as ILoginFormError).error
          : 'An error ocurred',
      });
  }

  return (
    <>
      <div className="media-section__subtitle">
        <h2 className="media-h2">Step 4</h2>
      </div>
      <div className="form-container">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label for="artistName">Artist Name *</Label>
            <Controller
              name="artistName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  id="artistName"
                  placeholder="Enter the artist's name"
                  type="text"
                  invalid={!!errors.artistName}
                  {...field}
                />
              )}
            />
            <FormFeedback>{"The artist's name is required"}</FormFeedback>
          </FormGroup>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="submit" onClick={() => clearSelectedOptions()}>
              Search
            </Button>
          </div>
        </Form>
      </div>
      {showResultsContainer && (
        <div className="media-section media-mt-1">
          {isGetArtistOptionsLoading || isGetArtistOptionsFetching ? (
            <div className="list-no-item-add">
              <LoadingSpinner />
            </div>
          ) : isGetArtistOptionsError ? (
            <div className="list-no-item-add">
              <img src={errorIcon} alt="error-icon" />
              <p>{getArtistOptionsError?.error}</p>
            </div>
          ) : Object.keys(getArtistOptionsData).length !== 0 ? (
            <SearchResults
              data={getArtistOptionsData}
              selectedImdbResult={selectedImdbResult}
              setSelectedImdbResult={setSelectedImdbResult}
            />
          ) : null}
        </div>
      )}
      {enableButton && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={() => setSelectedArtist()}>Add</Button>
        </div>
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '30px',
        }}
      >
        <Button
          onClick={() => setArtistSearchData()}
          disabled={!selectedArtists.length}
        >
          Submit
        </Button>
      </div>
    </>
  );
};
